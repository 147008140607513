import { useQuery } from '@tanstack/react-query';

import { hasKey } from '~/utils';
import { config } from '~/config';
import { useRequest } from '~/lib/useRequest';
import { GetApplicationResponse, ApplicationResource } from '@ab/ams-school-api/types/private/v1';

export type Application = ApplicationResource['attributes'] & { id: string };
export type ApplicationStatus = Application['status'];
export type Applicant = Application['applicant'];
export type ApplicantAddress = Applicant['addresses']['residentialAddress'];
export type AttachmentDocument = Application['supportingDocuments'][number];
export type StatusAndCitizenship = Application['statusAndCitizenship'];
export type EducationHistory = Application['educationHistories'][number];
export type LanguageProficiency = Application['languageProficiency'];

export function useApplication(id: string) {
  const request = useRequest();

  const { isLoading, isFetching, error, data, isError } = useQuery({
    queryKey: ['application', id],
    queryFn: async () => {
      const response = await request<GetApplicationResponse>(`${config.apiHost}/private/v1/applications/${id}`, {
        method: 'GET',
        isExpectedResponse,
      });

      const app = response.data;
      const attrs = app.attributes;
      return {
        ...attrs,
        id: app.id,
      } as Application;
    },
  });
  return {
    isLoadingApplication: isLoading,
    isFetchingApplication: isFetching,
    applicationError: error,
    application: data,
    isApplicationError: isError,
  };
}

function isExpectedResponse(res: unknown): res is GetApplicationResponse {
  return Boolean(res && typeof res === 'object' && hasKey('data', res));
}
