import { ButtonLink } from '@applyboard/crystal-ui';
import { AttachmentDocument } from '../../data/useApplication';

type ApplicationLetterLinkProps = { label: string; letter: AttachmentDocument };
export function ApplicationLetterLink({ label, letter }: ApplicationLetterLinkProps) {
  return (
    <ButtonLink
      aria-label={label}
      emphasis="transparent"
      href={letter.downloadUrl!}
      intent="primary"
      size="lg"
      width="fill"
      mode="override_user_choice_and_open_in_new_tab"
    >
      {label}
    </ButtonLink>
  );
}
