import { WebStorageStateStore } from 'oidc-client-ts';
import React, { useEffect } from 'react';
import { AuthProvider, hasAuthParams, AuthProviderProps, useAuth as useOidcAuth } from 'react-oidc-context';
import { useAppProps } from '~/lib/useAppProps';
import { FourOOne } from '~/pages/ErrorPage/401';
import { LoadingPage } from '~/pages/LoadingPage';

const logoutContext = React.createContext<[boolean, (status: boolean) => void]>([false, () => null]);
const OIDC_USER_STORAGE_PREFIX = 'oidc.user';
// Note: Implemented to have more control over the use of "id_token" via the "getAccessToken" method.
// This is required to validate against the source of truth i.e. the browser-level storage for valid OIDC key.
// Without a valid OIDC key, we consider the session not authenticated.
export function useAuth() {
  const { oauth } = useAppProps();
  const [, setState] = React.useContext(logoutContext);
  const auth = useOidcAuth();
  return {
    isLoading: auth.isLoading,
    isAuthenticated: auth.isAuthenticated,
    getAccessToken: () => {
      const aud = auth.user?.profile?.aud;
      const iss = auth.user?.profile?.iss;
      if (iss && aud && typeof aud === 'string') {
        if (window.localStorage.getItem(`${OIDC_USER_STORAGE_PREFIX}:${iss}:${aud}`)) {
          return auth.user?.id_token;
        }
      }
      return undefined;
    },
    logout: async () => {
      setState(true);
      await auth.removeUser();
      await auth.revokeTokens();
      await auth.clearStaleState();
      window.location.href = `${oauth.logoutUri}?client_id=${auth.settings.client_id}&logout_uri=${auth.settings.post_logout_redirect_uri}&redirect_uri=${auth.settings.redirect_uri}&response_type=code`;
    },
  };
}

function AutoSignIn(props: { children: JSX.Element }) {
  const auth = useOidcAuth();
  const [logoutState] = React.useContext(logoutContext);
  useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !logoutState) {
      auth.signinRedirect();
    }
  }, [auth, logoutState]);

  if (auth.isLoading || !auth.isAuthenticated) {
    return <LoadingPage />;
  }

  if (auth.error) {
    //TODO: report {auth.error.message}
    return <FourOOne />;
  }

  return props.children;
}

const onSigninCallback = (): void => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

export function Authenticator(props: { children: JSX.Element }) {
  const [state, setState] = React.useState(false);
  const appProps = useAppProps();

  const authConfig: AuthProviderProps = {
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    authority: appProps.oauth.authority,
    client_id: appProps.oauth.clientId,
    redirect_uri: `${window.location.protocol}//${window.location.host}/applications`,
    post_logout_redirect_uri: `${window.location.protocol}//${window.location.host}/login`,
    onSigninCallback: onSigninCallback,
    revokeTokensOnSignout: true,
  };
  return (
    <AuthProvider {...authConfig}>
      <logoutContext.Provider value={[state, setState]}>
        <AutoSignIn>{props.children}</AutoSignIn>
      </logoutContext.Provider>
    </AuthProvider>
  );
}
