import { Flex, Button } from '@applyboard/crystal-ui';
import {
  ProgramDetails,
  ApplicationDetailsHeading,
  StatusAndCitizenship,
  ContactInformation,
  ApplicantInformation,
  EducationHistory,
  LanguageProficiency,
  OtherRequiredDocuments,
  ApplicationProcessingActions,
} from './../components';
import { Application } from '../data/useApplication';
import { useApplicationDetailsCardsState } from '../context/ApplicationDetailsCardsStateProvider';
import { BackButton } from '~/components/BackButton';

type MobileLayoutProps = { application: Application };

export function MobileLayout(props: MobileLayoutProps) {
  const { setAllCardsState, allExpanded, allCollapsed } = useApplicationDetailsCardsState();

  return (
    <div>
      <Flex pt={10} pb={2} px={8} gap={6} direction="column">
        <BackButton title="Applications List" href="/applications" />
      </Flex>
      <Flex px={8} gap={6} direction="column">
        <Flex justify="between" align="stretch" direction="column">
          <ApplicationDetailsHeading application={props.application} />
          <ApplicationProcessingActions application={props.application} />
        </Flex>
        <ProgramDetails application={props.application} lineSplit={true} />
        <Flex align="stretch" as="div" gap={5} justify="end">
          <Button emphasis="transparent" disabled={allExpanded} onClick={() => setAllCardsState(true)}>
            Expand all
          </Button>
          <Button disabled={allCollapsed} onClick={() => setAllCardsState(false)} emphasis="transparent">
            Collapse all
          </Button>
        </Flex>
        <Flex gap={8} align="stretch" direction="column">
          <Flex gap={8} direction="column">
            <ApplicantInformation applicant={props.application.applicant} />
            <ContactInformation
              contactInfo={props.application.applicant.contactInformation}
              emergencyContact={props.application.applicant.emergencyContact}
            />
            <StatusAndCitizenship statusAndCitizenship={props.application.statusAndCitizenship} />
            <EducationHistory educationHistories={props.application.educationHistories} />
            <LanguageProficiency languageProficiency={props.application.languageProficiency} />
            <OtherRequiredDocuments attachmentDocuments={props.application.supportingDocuments} />
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
}
