import { Flex, Text } from '@applyboard/crystal-ui';
import { DocumentOutlineIcon } from '@applyboard/ui-icons';
import { AttachmentDocument } from '../data/useApplication';
import { DetailsCard } from './DetailsCard';
import { AttachedDocuments } from './AttachedDocuments';

export function OtherRequiredDocuments(props: { attachmentDocuments: Array<AttachmentDocument> }) {
  return (
    <DetailsCard icon={DocumentOutlineIcon} title="Other Required Documents">
      <Flex justify="start" direction="column" gap={8}>
        {props.attachmentDocuments.length === 0 ? (
          <Text variant="bodyM" contrast="low">
            Not provided
          </Text>
        ) : (
          <AttachedDocuments showTitle attachmentDocuments={props.attachmentDocuments} />
        )}
      </Flex>
    </DetailsCard>
  );
}
