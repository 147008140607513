import { useMediaQuery } from '~/hooks/useMediaQuery';
import { DesktopLayout } from './DesktopLayout';
import { MobileLayout } from './MobileLayout';
import { Application } from '../data/useApplication';

type ApplicationDetailsLayoutProps = { application: Application };

export const ApplicationDetailsLayout = (props: ApplicationDetailsLayoutProps) => {
  const { isUltraWide, isWide, isNormal, isNarrow, isUltraNarrow, isMobile } = useMediaQuery();

  if (isUltraWide || isWide || isNormal) {
    return <DesktopLayout application={props.application} programDetailsLineSplit={false} />;
  }

  if (isNarrow || isUltraNarrow) {
    return <DesktopLayout application={props.application} programDetailsLineSplit={true} />;
  }

  if (isMobile) {
    return <MobileLayout application={props.application} />;
  }

  // should not reach here
  return null;
};
