import { HttpError, GENERIC_ERROR_MESSAGE } from '~/lib/useRequest';
import { usePatchApplication } from '../../data/usePatchApplication';
import { useToasts } from '@applyboard/crystal-ui';
import { PreOfferLetterPreview, usePreOfferLetterPreview } from '../../data/usePreOfferLetterPreview';
import { RejectionLetterPreview, useRejectionLetterPreview } from '../../data/useRejectionLetterPreview';
import { useState } from 'react';

export type ApplicationProcessingDialog = 'preOffer' | 'decline' | 'none';

export function useApplicationProcessing(applicationId: string) {
  const { negative } = useToasts();
  const { patchApplication } = usePatchApplication(applicationId);
  const { generatePreOfferLetterPreview } = usePreOfferLetterPreview(applicationId);
  const { generateRejectionLetterPreview } = useRejectionLetterPreview(applicationId);
  const [dialogOpen, setApplicationProcessingDialogOpen] = useState<ApplicationProcessingDialog>('none');

  function setDialogOpen(name: ApplicationProcessingDialog, open: boolean) {
    setApplicationProcessingDialogOpen(open ? name : 'none');
  }

  async function passScreening(): Promise<void> {
    await patchApplication({ status: 'READY_FOR_ASSESSMENT' }).catch((err) => {
      if (HttpError.of(err).message === 'invalid status') {
        negative(new Error('Application is already screened, please refresh the page'));
      } else {
        negative(new Error(GENERIC_ERROR_MESSAGE));
      }
      throw err;
    });
  }

  async function generatePreOffer(props: {
    conditions: Array<string>;
    customCondition: string;
  }): Promise<PreOfferLetterPreview> {
    return await generatePreOfferLetterPreview({
      conditions: props.conditions,
      customCondition: props.customCondition,
    }).catch((err) => {
      negative(new Error(GENERIC_ERROR_MESSAGE));
      throw err;
    });
  }
  async function generateRejection(rejectionReason: string): Promise<RejectionLetterPreview> {
    return await generateRejectionLetterPreview({ reason: rejectionReason }).catch((err) => {
      negative(new Error(GENERIC_ERROR_MESSAGE));
      throw err;
    });
  }

  async function issuePreOffer(conditions: Array<string>, customCondition: string): Promise<void> {
    await patchApplication({
      status: 'INITIAL_OFFER',
      conditions: conditions,
      customCondition: customCondition,
    }).catch((err) => {
      negative(new Error(GENERIC_ERROR_MESSAGE));
      throw err;
    });
  }

  async function declineApplicant(reason: string): Promise<void> {
    await patchApplication({ status: 'DECLINED', rejectionReason: reason }).catch((err) => {
      if (HttpError.of(err).message === 'invalid status') {
        negative(new Error('Application is already declined, please refresh the page'));
      } else {
        negative(new Error(GENERIC_ERROR_MESSAGE));
      }
      throw err;
    });
  }

  return {
    passScreening,
    generatePreOffer,
    generateRejection,
    issuePreOffer,
    declineApplicant,
    dialogOpen,
    setDialogOpen,
  };
}
