import { useParams } from 'react-router-dom';
import { useApplication } from './data/useApplication';
import { Flex, Spinner } from '@applyboard/crystal-ui';
import { ApplicationDetailsLayout } from './layouts';
import { useWindowDocumentTitle } from '~/lib/useWindowDocumentTitle';
import { ApplicationDetailsCardsStateProvider } from './context/ApplicationDetailsCardsStateProvider';
import { ErrorPage, ErrorHandler } from '../ErrorPage';

export function ApplicationDetailsPage() {
  useWindowDocumentTitle('Application Details');
  const applicationId = useApplicationId();
  const { application, isLoadingApplication, applicationError, isApplicationError } = useApplication(applicationId); // applicationId always provided
  if (!isApplicationError && isLoadingApplication) {
    return (
      <Flex p={5} gap={5} align="center" direction="column" role="progressbar">
        <Spinner size="xl" />
      </Flex>
    );
  }
  if (isApplicationError) {
    return <ErrorHandler error={applicationError} />;
  }
  if (!application) {
    return <ErrorPage errorCode={404} customMessage="Failed to fetch the application details." />;
  }

  return (
    <ApplicationDetailsCardsStateProvider>
      <ApplicationDetailsLayout application={application} />
    </ApplicationDetailsCardsStateProvider>
  );
}

function useApplicationId() {
  const { id } = useParams() as { id: string };
  return id;
}
