import React from 'react';
import { PreOfferDialog } from './PreOfferDialog';
import { ActionGroup } from './ActionGroup';
import { useApplicationProcessing } from './useApplicationProcessing';
import { Application, ApplicationStatus } from '../../data/useApplication';
import { DeclineDialog } from './DeclineDialog';
import { NoticeContextProvider } from './useNotice';
import { ApplicationLetterLink } from './ApplicationLetterLink';

type ApplicationProcessingActionsProps = { application: Application };
export function ApplicationProcessingActions({ application }: ApplicationProcessingActionsProps) {
  const { passScreening, dialogOpen, setDialogOpen } = useApplicationProcessing(application.id);

  function onAction(status: ApplicationStatus['code']) {
    switch (status) {
      case 'READY_FOR_ASSESSMENT':
        return passScreening();
      case 'INITIAL_OFFER':
        return (async function () {
          setDialogOpen('preOffer', true);
        })();
      case 'DECLINED':
        setDialogOpen('decline', true);
        return Promise.resolve();
      default:
        return Promise.resolve(); // TODO for now
    }
  }

  return (
    <NoticeContextProvider>
      <DeclineDialog
        applicationId={application.id}
        open={dialogOpen === 'decline'}
        onClose={() => setDialogOpen('decline', false)}
      />
      <PreOfferDialog
        applicationId={application.id}
        open={dialogOpen === 'preOffer'}
        openCloseDialog={(open) => {
          setDialogOpen('preOffer', open);
        }}
      />
      {application.preOfferLetter && (
        <ApplicationLetterLink letter={application.preOfferLetter} label="View Pre-Offer Letter" />
      )}
      {application.rejectionLetter && (
        <ApplicationLetterLink letter={application.rejectionLetter} label="View Decline Letter" />
      )}
      {application.status.actionGroups.map((actionGroup) => (
        <ActionGroup key={actionGroup.title} actionGroup={actionGroup} onAction={onAction} />
      ))}
    </NoticeContextProvider>
  );
}
