import { Flex, GridAlpha, Button } from '@applyboard/crystal-ui';
import {
  ProgramDetails,
  ApplicationDetailsHeading,
  StatusAndCitizenship,
  ContactInformation,
  ApplicantInformation,
  EducationHistory,
  LanguageProficiency,
  OtherRequiredDocuments,
  ApplicationProcessingActions,
} from './../components';
import { Application } from '../data/useApplication';
import { useApplicationDetailsCardsState } from '../context/ApplicationDetailsCardsStateProvider';
import { BackButton } from '~/components/BackButton';
import React from 'react';

type DesktopLayoutProps = { application: Application; programDetailsLineSplit: boolean };

export function DesktopLayout(props: DesktopLayoutProps) {
  const { setAllCardsState, allExpanded, allCollapsed } = useApplicationDetailsCardsState();

  return (
    <div>
      <Flex pt={10} pb={2} px={8} gap={6} direction="column">
        <BackButton title="Applications List" href="/applications" />
      </Flex>
      <Flex px={8} gap={6} direction="column">
        <Flex justify="between" align="center">
          <ApplicationDetailsHeading application={props.application} />
          <ApplicationProcessingActions application={props.application} />
        </Flex>
        <ProgramDetails application={props.application} lineSplit={props.programDetailsLineSplit} />
        <Flex align="stretch" as="div" gap={5} justify="end">
          <Button emphasis="transparent" disabled={allExpanded} onClick={() => setAllCardsState(true)}>
            Expand all
          </Button>
          <Button disabled={allCollapsed} onClick={() => setAllCardsState(false)} emphasis="transparent">
            Collapse all
          </Button>
        </Flex>
        <Flex gap={8} align="stretch" direction="column">
          <GridAlpha columns="repeat(2,1fr)" areas={['left right']} columnGap={10}>
            <GridAlpha.Item areaName="left">
              <Flex gap={8} direction="column">
                <ApplicantInformation applicant={props.application.applicant} />
                <ContactInformation
                  contactInfo={props.application.applicant.contactInformation}
                  emergencyContact={props.application.applicant.emergencyContact}
                />
                <StatusAndCitizenship statusAndCitizenship={props.application.statusAndCitizenship} />
              </Flex>
            </GridAlpha.Item>
            <GridAlpha.Item areaName="right">
              <Flex gap={8} direction="column">
                <EducationHistory educationHistories={props.application.educationHistories} />
                <LanguageProficiency languageProficiency={props.application.languageProficiency} />
                <OtherRequiredDocuments attachmentDocuments={props.application.supportingDocuments} />
              </Flex>
            </GridAlpha.Item>
          </GridAlpha>
        </Flex>
      </Flex>
    </div>
  );
}
