import React from 'react';
import { CrystalProvider } from '@applyboard/crystal-ui';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { RouterProvider, Navigate, createBrowserRouter } from 'react-router-dom';
import { Authenticator } from './lib/useAuth';
import { AppProps } from './lib/useAppProps';

import { reactRouterV6Routes } from './Routes';
import { Layout } from './components/Layout';
import { CustomLink } from './components/CustomLink';
import { ErrorPage } from './pages/ErrorPage';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchIntervalInBackground: false,
      refetchOnReconnect: false,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    },
  },
});

export const router = createBrowserRouter([
  {
    path: '',
    handle: { crumb: undefined },
    element: <Navigate to="/applications" />,
  },
  {
    path: '/',
    element: <Layout />,
    children: reactRouterV6Routes,
  },
  {
    path: '*',
    element: <ErrorPage errorCode={404} customMessage="Page Not Found" />,
  },
]);

function App() {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <CrystalProvider linkAs={CustomLink as any}>
      <QueryClientProvider client={queryClient}>
        <AppProps>
          <Authenticator>
            <RouterProvider router={router} />
          </Authenticator>
        </AppProps>
      </QueryClientProvider>
    </CrystalProvider>
  );
}

export default App;
