import { useQuery } from '@tanstack/react-query';

import { hasKey } from '~/utils';
import { config } from '~/config';
import { useRequest } from '~/lib/useRequest';
import {
  ListApplicationRejectionReasons,
  ApplicationRejectionReasonResource,
} from '@ab/ams-school-api/types/private/v1';

export type ApplicationRejectionReason = ApplicationRejectionReasonResource['attributes'] & { id: string };

export function useApplicationRejectionReason() {
  const request = useRequest();

  const { isLoading, isFetching, error, data, isError } = useQuery({
    queryKey: ['application_rejection_reasons'],
    queryFn: async () => {
      const response = await request<ListApplicationRejectionReasons>(
        `${config.apiHost}/private/v1/rejection_reasons?page[number]=1&page[size]=100`,
        {
          method: 'GET',
          isExpectedResponse,
        }
      );

      const reasons = response.data;
      return reasons.map((reason) => {
        return {
          id: reason.id,
          value: reason.attributes.value,
          label: reason.attributes.label,
        };
      });
    },
  });
  return {
    isLoadingRejectionReason: isLoading,
    isFetchingRejectionReason: isFetching,
    rejectionReasonError: error,
    rejectionReasons: data,
    isRejectionReasonError: isError,
  };
}

function isExpectedResponse(res: unknown): res is ListApplicationRejectionReasons {
  return Boolean(res && typeof res === 'object' && hasKey('data', res));
}
