import {
  Dialog,
  Button,
  Flex,
  Text,
  ButtonLink,
  CheckboxGroup,
  Spinner,
  TextInput,
  useToasts,
} from '@applyboard/crystal-ui';
import { DocumentCheckOutlineIcon, ArrowRightOutlineIcon } from '@applyboard/ui-icons';
import { useApplicationAdmissionCondition } from '../../data/useAdmissionConditions';
import { useApplicationProcessing } from './useApplicationProcessing';
import React from 'react';
import { useNotice } from './useNotice';
import { DialogHeader, STEPS } from './DialogHeading';
import { GENERIC_ERROR_MESSAGE } from '~/lib/useRequest';

export function PreOfferDialog({
  open,
  openCloseDialog,
  applicationId,
}: {
  open: boolean;
  openCloseDialog: (open: boolean) => void;
  applicationId: string;
}) {
  const [loading, setLoading] = React.useState(false);
  const [downloadUrl, setDownloadUrl] = React.useState('');
  const [conditions, setConditions] = React.useState<Array<string>>([]);
  const [customCondition, setCustomCondition] = React.useState('');
  const [stepState, setStepState] = React.useState<number>(STEPS.STEP1);
  const { issuePreOffer, generatePreOffer } = useApplicationProcessing(applicationId);
  const { setNotice } = useNotice();
  const { negative } = useToasts();

  function onOpenChange(open: boolean) {
    openCloseDialog(open);
    if (!open) {
      setStepState(STEPS.STEP1);
      setCustomCondition('');
      setConditions([]);
    }
  }

  async function onSubmit() {
    try {
      setLoading(true);
      await issuePreOffer(conditions, customCondition);
      openCloseDialog(false);
      setNotice('Successfully sent pre-offer letter. It will be available here shortly.', 'positive');
    } catch (_error) {
      negative(new Error(GENERIC_ERROR_MESSAGE));
    } finally {
      setLoading(false);
    }
  }

  async function onButtonClick1() {
    try {
      setLoading(true);
      const { letterPreviewDownloadUrl } = await generatePreOffer({ conditions, customCondition });
      setDownloadUrl(letterPreviewDownloadUrl);
    } catch (_error) {
      negative(new Error(GENERIC_ERROR_MESSAGE));
    } finally {
      setLoading(false);
      setStepState(STEPS.STEP2);
    }
  }
  if (!open) return null;

  return (
    <Dialog onOpenChange={onOpenChange} size="md" open={true}>
      <Dialog.Heading>
        <DialogHeader setStepState={setStepState} stepState={stepState} heading="Issue Pre-offer" />
      </Dialog.Heading>
      <Dialog.Content>
        <Flex direction="column" gap={8}>
          {stepState === STEPS.STEP1 && (
            <AdmissionConditionGroup
              conditions={conditions}
              setConditions={setConditions}
              customCondition={customCondition}
              setCustomCondition={setCustomCondition}
            />
          )}
          {stepState === STEPS.STEP2 && (
            <>
              <ButtonLink
                aria-label="Download Pre-offer LOA"
                emphasis="highlighted"
                href={downloadUrl}
                intent="primary"
                size="lg"
                leadIcon={DocumentCheckOutlineIcon}
                width="fill"
                mode="override_user_choice_and_open_in_new_tab"
              >
                Pre-offer LOA
              </ButtonLink>
              <Text variant="bodyM" intent="secondary">
                A preview of the pre-offer letter is available before sending it to the applicant. Once the letter is
                sent, you will not be able to make changes.
              </Text>
            </>
          )}
        </Flex>
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.CloseButton disabled={loading} width="fill">
          Cancel
        </Dialog.CloseButton>
        {stepState === STEPS.STEP1 && (
          <Button
            intent="primary"
            loading={loading}
            width="fill"
            trailIcon={ArrowRightOutlineIcon}
            onClick={onButtonClick1}
          >
            Next
          </Button>
        )}
        {stepState === STEPS.STEP2 && (
          <Button intent="primary" loading={loading} onClick={onSubmit} width="fill">
            Send Pre-offer
          </Button>
        )}
      </Dialog.Actions>
    </Dialog>
  );
}

function AdmissionConditionGroup(props: {
  setConditions: (conditions: Array<string>) => void;
  conditions: Array<string>;
  setCustomCondition: (condition: string) => void;
  customCondition: string;
}) {
  const { admissionConditions, isFetchingAdmissionCondition } = useApplicationAdmissionCondition();

  if (isFetchingAdmissionCondition) {
    return (
      <Flex direction="column" align="center">
        <Flex>
          <Spinner />
        </Flex>
      </Flex>
    );
  }

  return (
    <>
      <CheckboxGroup
        onChange={(conditions: Array<string>) => {
          props.setConditions(conditions);
        }}
        value={props.conditions}
        label="You can optionally choose one or more conditions so the student can be accepted in the program."
      >
        <Flex direction="column">
          {admissionConditions &&
            admissionConditions.map((i) => <CheckboxGroup.Option label={i.label} value={i.value} key={i.id} />)}
        </Flex>
      </CheckboxGroup>
      <TextInput
        label="Custom condition"
        intent="secondary"
        onChange={(condition: string) => props.setCustomCondition(condition)}
        value={props.customCondition}
        helpText="Add any conditions that are not included in the list above"
      />
    </>
  );
}
